// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/Bitterbrush DEMO.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Bitterbrush';
    src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  }

.navbar {
    height:50px;
    position:fixed;
    right:0px;
}

.navbar-element {
    padding: 10px;
    text-decoration: none;
    color: grey;
    font-size: 2em;
    font-family: 'Bitterbrush';
}

.navbar-element:hover {
    color:rgb(148, 21, 21);
}
  `, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,8DAA0D;EAC5D;;AAEF;IACI,WAAW;IACX,cAAc;IACd,SAAS;AACb;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,WAAW;IACX,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["@font-face {\n    font-family: 'Bitterbrush';\n    src:url(\"/public/Bitterbrush DEMO.otf\") format(\"opentype\");\n  }\n\n.navbar {\n    height:50px;\n    position:fixed;\n    right:0px;\n}\n\n.navbar-element {\n    padding: 10px;\n    text-decoration: none;\n    color: grey;\n    font-size: 2em;\n    font-family: 'Bitterbrush';\n}\n\n.navbar-element:hover {\n    color:rgb(148, 21, 21);\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
