import React from 'react';
import './About.css';
function About(props) {
    return (
        <div className='container'>
            <h1>About</h1>
        </div>
    );
}

export default About;