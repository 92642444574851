// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/Bitterbrush DEMO.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Bitterbrush';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

.container {
  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: column;
  height:100%;
}

.page-layout {
  display: flex;
  justify-content:center;
  align-items: center;
}

.external-links {
  flex: 1 1;
  margin-right: auto;
  font-family: "Bitterbrush";
}

.link {
  text-decoration: none;
  color: grey;
  font-size: 2em;
}

.link:hover {
  color:rgb(148, 21, 21);
}

.title-section {
  flex:1 1;
  height:auto;
  margin-left: auto; 
  font-family: "Bitterbrush";
  font-size: 2em;
  color: grey;
  text-align: right;
  margin-right: 2em;
}
.photo-container {
 height:max-content;
}

.photo {
  flex: 1 1;
  border-radius:50%;
  width: 20vw;
  height:20vw;
  object-fit: cover;
  pointer-events: none;
  border: 5px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,8DAA0D;AAC5D;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,QAAM;EACN,WAAW;EACX,iBAAiB;EACjB,0BAA0B;EAC1B,cAAc;EACd,WAAW;EACX,iBAAiB;EACjB,iBAAiB;AACnB;AACA;CACC,kBAAkB;AACnB;;AAEA;EACE,SAAO;EACP,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,uBAAuB;AACzB","sourcesContent":["@font-face {\n  font-family: 'Bitterbrush';\n  src:url(\"/public/Bitterbrush DEMO.otf\") format(\"opentype\");\n}\n\n.container {\n  text-align: center;\n  display:flex;\n  justify-content: center;\n  flex-direction: column;\n  height:100%;\n}\n\n.page-layout {\n  display: flex;\n  justify-content:center;\n  align-items: center;\n}\n\n.external-links {\n  flex: 1;\n  margin-right: auto;\n  font-family: \"Bitterbrush\";\n}\n\n.link {\n  text-decoration: none;\n  color: grey;\n  font-size: 2em;\n}\n\n.link:hover {\n  color:rgb(148, 21, 21);\n}\n\n.title-section {\n  flex:1;\n  height:auto;\n  margin-left: auto; \n  font-family: \"Bitterbrush\";\n  font-size: 2em;\n  color: grey;\n  text-align: right;\n  margin-right: 2em;\n}\n.photo-container {\n height:max-content;\n}\n\n.photo {\n  flex: 1;\n  border-radius:50%;\n  width: 20vw;\n  height:20vw;\n  object-fit: cover;\n  pointer-events: none;\n  border: 5px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
